import { lazy } from 'react';
import { GlobalProvider } from '../context/GlobalProvider';
import AppRoutes from './AppRoutes';

const PageLayout = lazy(() => import('../components/core/PageLayout'));

const App: React.FC = () => {
  return (
    <GlobalProvider>
      <PageLayout>
        <AppRoutes />
      </PageLayout>
    </GlobalProvider>
  );
};

export default App;